import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { getBalanceAndPin } from "../../features/balance-and-pin/balanceAndPinSlice";
import { SpinnerContainer } from "../../components/styled/Spinner";
import Spinner from "../../components/Spinner";
import {
  AdviceItem,
  AdviceList,
  Footer,
  Header,
  MainContainer,
  TextContainer
} from "./styles/ElectricalSafety";

import bgImage from "./img/es-background.webp";
import image1 from "./img/image-1.webp";
import image2 from "./img/image-2.webp";
import image3 from "./img/image-3.webp";
import image4 from "./img/image-4.webp";
import image5 from "./img/image-5.webp";

const ElectricalSafety = () => {
  const [searchParams] = useSearchParams();
  const serialNumber = searchParams.get("serialNumber");

  const dispatch = useDispatch();
  const { balanceAndPin, isLoading } = useSelector(
    (state) => state.balanceAndPin
  );

  useEffect(() => {
    if (!balanceAndPin && serialNumber) {
      dispatch(
        getBalanceAndPin({
          serial: serialNumber,
          query: "?provider=pannovate"
        })
      );
    }
  }, [balanceAndPin, serialNumber, dispatch]);
  if (isLoading)
    return (
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    );

  return (
    <MainContainer>
      <Header>
        <img src={bgImage} alt="header-background" />
        <TextContainer>
          <h1>Home Office Electrical Safety</h1>
          <p>
            Working from home? Follow these electrical safety tips to keep you,
            your family, and home safe from electrical hazards.
          </p>
        </TextContainer>
      </Header>
      <AdviceList>
        <AdviceItem>
          <img src={image1} alt="advice" />
          <div>
            Unplug appliances when not in use to minimise the risk of shock and
            fire.
          </div>
        </AdviceItem>
        <AdviceItem>
          <img src={image2} alt="advice" />
          <div>Avoid overloading outlets.</div>
        </AdviceItem>
        <AdviceItem
          onClick={() =>
            window.open("https://www.nationaldahelpline.org.uk/", "_blank")
          }
        >
          <img src={image3} alt="advice" />
          <div>Never run cords under rugs, carpets, doors or windows.</div>
        </AdviceItem>
        <AdviceItem>
          <img src={image4} alt="advice" />
          <div>Make sure you use proper wattage for lamps/lighting.</div>
        </AdviceItem>
        <AdviceItem>
          <img src={image5} alt="advice" />
          <div>
            Keep papers and other combustibles at least 3 feet from heat
            sources.
          </div>
        </AdviceItem>
      </AdviceList>

      <Footer>
        <h2>
          For more information go to:{" "}
          <b>
            <a href="https://www.hse.gov.uk/toolbox/electrical.htm">
              https://www.hse.gov.uk
            </a>
          </b>
        </h2>

        {balanceAndPin?.balanceDetails.availableBalance &&
          balanceAndPin?.pinDetails?.pin &&
          balanceAndPin?.activationCode && (
            <h2>
              Doc Reference:{" "}
              <b>{`${balanceAndPin.balanceDetails.availableBalance}-${balanceAndPin.pinDetails?.pin}-${balanceAndPin.activationCode}`}</b>
            </h2>
          )}
      </Footer>
    </MainContainer>
  );
};

export default ElectricalSafety;
