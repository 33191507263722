import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { getBalanceAndPin } from "../../features/balance-and-pin/balanceAndPinSlice";
import ExpandingContainer from "./ExpandingContainer";

import {
  ButtonWithShadow,
  Footer,
  MainContainer,
  Navbar,
  StepContainer,
  StepNumber,
  AppStoreLinks
} from "./styles/Pay360";

import digiseqLogo from "./img/digiseq-logo.png";
import pay360Logo from "./img/pay360-logo.png";
import appStoreLogo from "./img/app-store.svg";
import gplayLogo from "./img/play-store.svg";
import papyrusLogo from "./img/papyrus.png";
import digiseqLogoSVG from "./img/digiseq-svg.svg";
import guavapayLogo from "./img/guava-logo.svg";
import myGuavaLogo from "./img/my-guava.svg";
import payAnyWhereImage from "../../assets/pay-anywhere-image.png";
import Spinner from "../../components/Spinner";
import { formattedBalance } from "../../features/balance-and-pin/utils";

const Pay360 = () => {
  const [expandSection, setExpandSection] = useState({
    guavapay: false,
    digiseq: false
  });

  const [searchParams] = useSearchParams();
  const serialNumber = searchParams.get("serialNumber");
  const dispatch = useDispatch();

  const { balanceAndPin, isLoading } = useSelector(
    (state) => state.balanceAndPin
  );

  useEffect(() => {
    if (!balanceAndPin && serialNumber) {
      dispatch(
        getBalanceAndPin({
          serial: serialNumber,
          query: "?provider=recipe-harvest"
        })
      );
    }
  }, [balanceAndPin, serialNumber, dispatch]);

  const appStoreLinks = (appStoreUrl, playStoreUrl) => {
    return (
      <AppStoreLinks>
        <img
          src={appStoreLogo}
          alt="app-store"
          onClick={() => window.open(appStoreUrl, "_blank")}
        />
        <img
          src={gplayLogo}
          alt="play-store"
          onClick={() => window.open(playStoreUrl, "_blank")}
        />
      </AppStoreLinks>
    );
  };

  if (isLoading) return <Spinner />;

  return (
    <MainContainer>
      <Navbar>
        <img src={pay360Logo} alt="pay360" />
        <div className="balance">
          {balanceAndPin?.balanceDetails?.availableBalance ? (
            <p>
              {formattedBalance(
                balanceAndPin.balanceDetails.availableBalance,
                balanceAndPin?.balanceDetails.currency
              )}
            </p>
          ) : (
            <p>{"N/A"}</p>
          )}
        </div>
      </Navbar>
      <ExpandingContainer title={"Welcome!"}>
        <p>
          {
            "Welcome to PAY360 2025. Treat this keyfob as cash. It is pre-loaded for you to use as you like at any of the event’s Tap n Taste areas. Your keyfob will not work outside of the exhibition until the event is over."
          }
        </p>
        <p>
          {
            "The good news is, you can take this keyfob as a gift from us to use at any retailers once the event is over."
          }
        </p>
        <p>
          {
            "Any problems, please visit us at the information desk as you enter the exhibition."
          }
        </p>
      </ExpandingContainer>

      <ExpandingContainer title={"TODAY'S SCHEDULE"}>
        <ButtonWithShadow
          onClick={() =>
            window.open("https://pay360event.com/agenda/", "_blank")
          }
        >
          {"Visit the Schedule"}
        </ButtonWithShadow>
      </ExpandingContainer>
      <ExpandingContainer title={"OUR CHARITY WORK"}>
        <img className="section-logo" src={papyrusLogo} alt="papyrus" />
        <p>
          Pay360 is supporting Papyrus by donating any left over balance to this
          worth cause. You do not have to hand your keyfob back to do so. The
          excess fund will simply be reverted back post PAY360.
        </p>
        <p>We will be in touch to let you know the total amount we raised.</p>
        <p>Find more information below: </p>
        <p>
          <a
            href="https://www.teenagecancertrust.org/about-us"
            target="_blank"
            rel="noopener noreferrer"
          >
            Our Charity Partner: PAPYRUS
          </a>
        </p>
      </ExpandingContainer>
      <ExpandingContainer customColor="#FF6400" title={"DIGISEQ"}>
        <img className="section-logo" src={digiseqLogoSVG} alt="digiseq" />
        <p>
          {
            "DIGISEQ is an award-winning IoT service platform that securely provisions data into everyday wearables; enabling items, from rings to clothing, to deliver NFC payment, customer engagement functionality, access control and digital identity. We connect the ecosystem, serving as a centrepiece between banks, product creators, chip manufacturers and card bureaux, so that wearables can be simply and securely delivered into the hands of consumers."
          }
        </p>
        {!expandSection.digiseq && (
          <p
            className="show-more"
            onClick={() =>
              setExpandSection((sections) => ({
                ...sections,
                digiseq: !sections.digiseq
              }))
            }
          >
            show more
          </p>
        )}
        {expandSection.digiseq && (
          <>
            <p>
              <strong>Delivering the highest level of Security</strong>
            </p>
            <p>
              DIGISEQ’s RCOS™ over-the-air mobile personalisation and
              tokenisation service is leading the way in adopting the new SCP03
              standard, ensuring all provisioned payment data is fully protected
              to the highest available standards, while continuing to deliver
              the fastest self-provisioning service in the industry. SCP03
              protocol supports AES (Advanced Encryption Standard) based
              cryptography in lieu of TDEA (Triple Data Encryption Algorithm).
              The protocol protects bidirectional communication between the host
              and cards, mobile wallets and wearables.
            </p>
            <p>
              We also adhere to PSD2 directive for consumer initiated electronic
              payments, which mandates two factor authentication outside of the
              normal card present exemptions. With the support of online PIN, a
              transaction that exceeds the local contactless value or
              consecutive/cumulative transaction limit, will prompt the terminal
              to request the customer to enter the PIN (of the original card)
              therefore providing the exact same user experience as that of a
              card transaction.
            </p>
          </>
        )}
        {expandSection.digiseq && (
          <p
            className="show-more"
            onClick={() =>
              setExpandSection((sections) => ({
                ...sections,
                digiseq: !sections.digiseq
              }))
            }
          >
            show less
          </p>
        )}
      </ExpandingContainer>
      <ExpandingContainer customColor="#009870" title={"GUAVAPAY"}>
        <img className="section-logo" src={guavapayLogo} alt="guavapay" />

        <p>
          <a
            href="https://www.guavapay.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Guavapay
          </a>
          {
            " is a global fintech company established in 2017 and headquartered in London, UK. With a mission of global financial inclusion, the company delivers end-to-end payment solutions designed to meet the evolving payment needs for individuals and businesses offering seamless, secure, and efficient financial services. Guavapay’s flagship products "
          }
          <a
            href="https://www.guavapay.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            MyGuava (B2C) and MyGuava Business (B2B)
          </a>{" "}
          deliver streamlined, cost-effective payment solutions.
        </p>
        {!expandSection.usc && (
          <p
            className="show-more"
            onClick={() =>
              setExpandSection((sections) => ({
                ...sections,
                guavapay: !sections.guavapay
              }))
            }
          >
            show more
          </p>
        )}
        {expandSection.guavapay && (
          <>
            <p>
              The MyGuava App is a universal financial tool that blends
              convenience, transparency and flexibility to meet the needs of
              global citizens, whether you’re on an international adventure or
              handling daily expenses. With a multi-currency account, seamless
              international transfers, personalised virtual cards and analytics
              tools, MyGuava simplifies money management both locally and
              internationally. A standout feature is the ability to send and
              receive funds around the world with zero fees and near-instant
              processing; for those who are always on the go or sending money to
              loved ones abroad, the MyGuava App is an essential
              companion. MyGuava Business is a payments solution provider for
              businesses of all size. It goes beyond traditional financial
              management by offering a diverse range of services that cover all
              payment needs, whether the customer transacts in person or online.
              From business accounts simplifying cross-border payments to
              payment terminals, e-commerce solutions, cards, payment links and
              invoice creation, the platform provides businesses with versatile
              tools to seamlessly manage transactions. These comprehensive
              offerings ensure that businesses can operate efficiently and
              effectively, no matter where or how they engage with their
              customers. Above anything, MyGuava Business wants to simplify
              financial management and democratise the possibilities of fintech.
            </p>
          </>
        )}
        {expandSection.usc && (
          <p
            className="show-more"
            onClick={() =>
              setExpandSection((sections) => ({
                ...sections,
                usc: !sections.usc
              }))
            }
          >
            show less
          </p>
        )}
      </ExpandingContainer>

      <ExpandingContainer customColor="#009870" title={"PAYMENT ACCOUNT"}>
        <img className="section-logo" src={myGuavaLogo} alt="my-guava" />

        <StepContainer>
          <StepNumber>1</StepNumber>
          <h2>Create a MyGuava Account</h2>
          <p>
            Register with MyGuava to receive a free compatible digital bank
            card.
          </p>
          <p>Download the MyGuava App:</p>

          {appStoreLinks(
            "https://apps.apple.com/us/app/myguava-all-things-payments/id1628100419",
            "https://play.google.com/store/apps/details?id=com.guavapay.mobile"
          )}
        </StepContainer>
        <StepContainer>
          <StepNumber>2</StepNumber>
          <h2>Link Your MyGuava Card Using the Manage-Mii App</h2>
          <p>
            Follow the instructions on the Manage-Mii app and use your new
            MyGuava Card details when prompted.
          </p>
          {appStoreLinks(
            "https://apps.apple.com/app/manage-mii/id6446908503",
            "https://play.google.com/store/apps/details?id=com.digiseq.managemii"
          )}
        </StepContainer>
        <StepContainer>
          <StepNumber>3</StepNumber>
          <h2>Pay Anywhere!</h2>
          <p>
            Tap your wearable at any card terminal where Mastercard Contactless
            is enabled.
          </p>

          <img
            className="pay-anywhere"
            src={payAnyWhereImage}
            alt="pay anywhere"
          />
        </StepContainer>
      </ExpandingContainer>
      <Footer>
        <p>Powered by</p>
        <img src={digiseqLogo} alt="digiseq" />
      </Footer>
    </MainContainer>
  );
};

export default Pay360;
