import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import { getBalanceAndPin } from "../../features/balance-and-pin/balanceAndPinSlice";

import Spinner from "../../components/Spinner";
import {
  MainContainer,
  NavBar,
  Footer,
  B1,
  B2,
  B3,
  H2
} from "./styles/SharedStyles";
import {
  EventInformation,
  EventItem,
  EventList,
  Feature,
  Features,
  Header,
  InfoAlert,
  Ownership,
  OwnershipItem,
  PaymentAccount,
  ProductContainer
} from "./styles/HugoBoss";

import logo from "./img/logo.png";
import chevron from "./img/chevron-right.svg";
import hkr2024Logo from "./img/hkr2024.png";
import cardInfo from "./img/card-info.svg";

const HB_DATA = {
  header: {
    title: "BOSS X PERFECT MOMENT WELCOMES YOU TO HAHNENKAMM 2024",
    image: require("./img/header.webp")
  },

  product: {
    title: "MY SMART JACKET",
    image: require("./img/product.webp"),
    description:
      "When the temptation to stay in bed is almost just too much, this wearable duvet will have you bundled up warm and ready to go. This version of our snuggly Samson puffer coat has been designed in collaboration with iconic fashion brand BOSS, featuring bold graphics and an empowering colour palette that promises to make a statement wherever your itinerary takes you. An elasticated waistband locks body heat in, while a built-in powder skirt, external ski pass pocket and internal goggle pocket have got all your ski-ssentials covered."
  },
  ownership: [
    {
      label: "Owner",
      info: "Robert Lindley"
    },
    {
      label: "Purchase date",
      info: "01/05/2023"
    }
  ],
  features: [
    {
      title: "Payment",
      image: require("./img/payment.webp"),
      hasAlert: true
    },
    {
      title: "Ski Pass",
      image: require("./img/ski-pass.webp")
    },
    {
      title: "Party",
      image: require("./img/party.webp")
    },
    {
      title: "Race Ticket",
      image: require("./img/race-ticket.webp")
    },
    {
      title: "My Gift",
      image: require("./img/my-gift.webp")
    },
    {
      title: "Mobile App",
      image: require("./img/mobile-app.webp")
    }
  ],
  schedule: [
    { time: "15:00", details: "Race 1" },
    { time: "18:00", details: "Hospitality area opening" },
    { time: "19:30", details: "Official welcoming" },
    { time: "19:45", details: "Ambassadors Q&As" },
    { time: "21:00", details: "Race 2" },
    { time: "21:20", details: "Cocktail Party at BOSS Chalet" },
    { time: "23:00", details: "Event closes" },
    { time: "00:00", details: "VIP After Party" }
  ],
  footerLinks: [
    {
      name: "FAQs",
      href: ""
    },
    {
      name: "Imprint",
      href: ""
    },
    {
      name: "Privacy Statement",
      href: ""
    },
    {
      name: "Privacy Statement HUGO BOSS EXPERIENCE",
      href: ""
    },
    {
      name: "Privacy Statement HUGO BOSS Newsletter",
      href: ""
    },
    {
      name: "Terms & Conditions",
      href: ""
    },
    {
      name: "Terms & Conditions HUGO BOSS EXPERIENCE",
      href: ""
    },
    {
      name: "Terms of Use",
      href: ""
    },
    {
      name: "Cookie settings",
      href: ""
    },
    {
      name: "App",
      href: ""
    }
  ]
};

const HugoBoss = () => {
  const [searchParams] = useSearchParams();
  const serialNumber = searchParams.get("serialNumber");
  const dispatch = useDispatch();

  const { balanceAndPin, isLoading } = useSelector(
    (state) => state.balanceAndPin
  );

  const [showAlert, setShowAlert] = useState(false);
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    if (!balanceAndPin && serialNumber) {
      dispatch(
        getBalanceAndPin({
          serial: serialNumber,
          query: "?provider=pannovate"
        })
      );
    }
  }, [balanceAndPin, serialNumber, dispatch]);

  const formattedBalance = (balanceDetails) => {
    const { availableBalance, currency } = balanceDetails;
    let iconPrefixed = true;
    let icon = "";

    switch (currency) {
      case "GBP":
        icon = "£";
        break;
      case "EUR":
        icon = "€";
        break;
      case "USD":
        icon = "$";
        break;
      default:
        icon = "";
    }

    if (iconPrefixed) {
      return icon + availableBalance;
    } else {
      return availableBalance + icon;
    }
  };

  const truncateString = (str) => (!showMore ? str.slice(0, 125) : str);

  if (isLoading) return <Spinner />;

  return (
    <MainContainer>
      <NavBar>
        <img src={logo} alt="hugo boss logo" className="logo" />
        <div className="balance-container">
          <B1>
            Balance:{" "}
            {balanceAndPin?.balanceDetails?.availableBalance
              ? formattedBalance(balanceAndPin?.balanceDetails)
              : "N/A"}
          </B1>
          <img
            src={cardInfo}
            onClick={() => setShowAlert(true)}
            alt={"card info"}
          />
        </div>
      </NavBar>
      <Header>
        <H2>{HB_DATA.header.title}</H2>
        <img src={HB_DATA.header.image} alt="header" />
      </Header>
      <ProductContainer>
        <H2>{HB_DATA.product.title}</H2>
        <img src={HB_DATA.product.image} alt="product" />
        <B2>
          {truncateString(HB_DATA.product.description)}
          {!showMore && "..."}
          <span onClick={() => setShowMore(!showMore)}>{`Show ${
            showMore ? "less" : "more"
          }`}</span>
        </B2>
      </ProductContainer>

      <Ownership>
        {HB_DATA.ownership.map((item, i) => (
          <OwnershipItem key={i}>
            <B1>{item.label}</B1>
            <B2>{item.info}</B2>
          </OwnershipItem>
        ))}
      </Ownership>
      <Features>
        {HB_DATA.features.map((item, i) => (
          <Feature
            isButton={item.hasAlert || item.link}
            key={i}
            onClick={() => item.hasAlert && setShowAlert(true)}
          >
            <img src={item.image} alt="feature" />
            <B2>{item.title}</B2>
          </Feature>
        ))}
      </Features>

      <EventInformation>
        <img src={hkr2024Logo} alt="hkr2024" />
        <EventList>
          {HB_DATA.schedule.map((evt, i) => (
            <EventItem key={i} isLast={i === HB_DATA.schedule.length - 1}>
              <B2>{evt.time}</B2>
              <B1>{evt.details}</B1>
            </EventItem>
          ))}
        </EventList>
      </EventInformation>

      <PaymentAccount>
        <B1>My payment account</B1>
        <img src={chevron} alt="chevron" />
      </PaymentAccount>

      <Footer>
        {HB_DATA.footerLinks.map((link, i) => (
          <a href={link.href} key={i}>
            <B3>{link.name}</B3>
          </a>
        ))}
        <B2>{"2023 HUGO BOSS All rights reserved"}</B2>
      </Footer>
      {showAlert && (
        <InfoAlert>
          <div className="inner-container">
            <H2>{"CONTACTLESS PAYMENTS"}</H2>
            <B2>
              {
                "Use your jacket as a contactless mastercard by tapping the cuff at any terminal point."
              }
            </B2>
            <B2>
              {
                "Top up your jacket by tapping on your balance at the top of the page."
              }
            </B2>
            <div className="button" onClick={() => setShowAlert(false)}>
              <B2>I understand</B2>
            </div>
          </div>
        </InfoAlert>
      )}
    </MainContainer>
  );
};

export default HugoBoss;
