import styled from "styled-components";

const H2 = {
  fontFamily: "Inter",
  fontSize: "24px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "32px"
};

export const Navbar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  img {
    width: 107px;
    height: 55px;
    flex-shrink: 0;
  }

  .balance {
    color: #3f2360;
    font-feature-settings: "clig" off, "liga" off;
    /* Title */
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
    letter-spacing: 0.15px;
    color: #3f2360;
  }
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 480px;
  margin: auto;
  min-height: 100vh;
  background-color: #fff;

  p {
    color: #202020;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
  }

  a {
    text-decoration: underline;
    cursor: pointer;
  }

  .stores {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    img {
      cursor: pointer;
    }
  }

  .show-more {
    display: inline;
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;
  }
`;

export const SectionContainer = styled.section`
  .section-logo {
    width: 100%;
    max-width: 297px;
    aspect-ratio: 297 / 79;
    height: auto;
    object-fit: contain;
  }
  border-bottom: 1px solid #d8d8d9;
  .title-bar {
    padding: 16px;
    background-color: ${({ customColor }) =>
      customColor ? customColor : "#523373"};

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    h2 {
      ${H2}
      color: white;
    }
    img {
      cursor: pointer;
    }
  }
`;

export const SectionContent = styled.div`
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  h2 {
    ${H2}
    span {
      color: #ff6400;
    }
  }
`;

export const Footer = styled.footer`
  padding: 24px 16px 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background-color: #523373;

  p {
    font-family: "Inter";
    font-weight: 100;
    font-size: 12px;
    color: white;
  }

  img {
    width: 117px;
    height: 33.724px;
    object-fit: contain;
  }
`;

export const ButtonWithShadow = styled.div`
  display: flex;
  padding: 16px;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: var(--Spacing-4, 4px);
  background: #fff;
  box-shadow: 0px 12px 16px -4px rgba(0, 0, 0, 0.08),
    0px 0px 6px -2px rgba(0, 0, 0, 0.05);
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
  letter-spacing: 0.15px;
`;

export const StepContainer = styled.div`
  padding: 32px 0px;

  p {
    margin-top: 16px;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.5px;
    columns: #626262;
  }
  .pay-anywhere {
    margin: 24px auto;
    width: 100%;
    object-fit: contain;
  }
  h2 {
    ${H2}
    margin-top: 16px;
  }
  border-top: 1px solid #e0e0e0;
`;
export const StepNumber = styled.div`
  display: flex;
  width: 40px;
  padding: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 360px;
  background: #f6f6f7;
`;

export const AppStoreLinks = styled.section`
  img {
    cursor: pointer;
    margin-top: 24px;
    margin-right: 8px;
  }
`;
