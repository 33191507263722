import styled from "styled-components";

const colors = {
  primary: "#56b609",
  secondary: "#88df42",
  background: "#f6f5ee",
  white: "#ffffff",
  formBackground: "#72dcc9",
  text: "#584232"
};

const fonts = {
  heading: "Barlow, sans-serif",
  body: "Barlow, sans-serif"
};

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 480px;
  margin: auto;
  background-color: ${colors.background};

  h1,
  h2 {
    font-family: ${fonts.heading};
    font-size: 1.5rem;
    font-weight: 700;
    color: ${colors.primary};
  }
  p {
    font-family: ${fonts.body};
    font-size: 1.25rem;
    color: ${colors.text};
  }
`;

export const Navbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
  border-bottom: 1px solid ${colors.secondary};
  background-color: ${colors.white};
`;

export const NavbarLogo = styled.img`
  width: 9.25rem;
  height: 3.375rem;
  img {
    vertical-align: bottom;
    margin-bottom: 0.5rem;
    max-width: 100%;
    height: auto;
  }
`;

export const Form = styled.form`
  margin: 6rem auto 6rem;
  background-color: ${colors.background};
  padding: 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
  .error-message {
    color: red;
  }
`;

export const Input = styled.input`
  background-color: ${colors.white};
  border: 1px solid ${colors.secondary};
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
`;

export const Button = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.secondary};
  color: ${colors.white};
  padding: 1rem 1.5rem;
  border-radius: 3rem;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 1.1;
  text-decoration: none;
  width: 100%;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  p {
    color: white;
    font-size: 0.875rem;
  }
  gap: 0.5rem;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem 1.5rem;
  background-color: ${colors.secondary};
  .logo {
    width: 9.25rem;
    height: 3.375rem;
    img {
      vertical-align: bottom;
      margin-bottom: 0.5rem;
      max-width: 100%;
      height: auto;
    }
  }
  .copyToClipboard {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
`;
