import styled from "styled-components";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 480px;
  margin: auto;
  min-height: 100vh;
  background: #ffffff;

  h1,
  h2,
  p {
    margin: 0;
    font-family: "Inter", sans-serif;
    color: #000000;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;

  img {
    width: 100%;
    margin: 0 auto;
    max-width: 200px;
  }
`;

export const BalanceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;

  h2 {
    font-size: 18px;
    font-weight: 600;
  }
`;

export const Button = styled.button`
  background-color: #000000;
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 5px;
  max-width: 200px;
  margin: 0 auto;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
`;

export const ErrorMessage = styled.p`
  color: red !important;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  padding: 20px;
`;
