import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import { getBalanceAndPin } from "../../features/balance-and-pin/balanceAndPinSlice";
import { formattedBalance } from "../../features/balance-and-pin/utils";
import { getDeviceByModel } from "../../features/devices/deviceSlice";
import { getDasDeviceInfo } from "../../features/das-device-manager/dasDeviceManagerSlice";

import {
  AfterEvent,
  EventInformation,
  Footer,
  GreenManifesto,
  MainContainer,
  MastercardIntro,
  NavBar,
  Wearable
} from "./styles/RolandGarros";

import Spinner from "../../components/Spinner";
import ExpandingContainer from "./ExpandingContainer";

import rrMastercardLogo from "./img/rr-mastercard-logo.svg";
import wearableImg from "./img/wearable.png";
import chevronRight from "./img/chevron-right.svg";
import bbLogo from "./img/bb.svg";
import appStoreLogo from "./img/app-store.svg";
import gplayLogo from "./img/play-store.svg";
import mmLogo from "./img/mm-logo.svg";
import digiseqLogo from "./img/digiseq-logo.png";
import paymeSwissLogo from "./img/payme-swiss-logo.png";
import copyIcon from "./img/copy-icon.svg";

const RolandGarros24 = () => {
  const [searchParams] = useSearchParams();
  const serialNumber = searchParams.get("serialNumber");
  const dispatch = useDispatch();

  const [readMore, setReadMore] = useState(false);

  const { balanceAndPin, isLoading } = useSelector(
    (state) => state.balanceAndPin
  );

  const { device: discoveryDevice, isLoading: isLoadingDevices } = useSelector(
    (state) => state.devices
  );

  const { dasDeviceInfo, gettingDasDeviceInfo } = useSelector(
    (state) => state.dasDevices
  );

  useEffect(() => {
    if (!dasDeviceInfo) {
      dispatch(getDasDeviceInfo(serialNumber));
    }
  }, [dasDeviceInfo, dispatch, serialNumber]);

  useEffect(() => {
    if (
      dasDeviceInfo?.model?.model &&
      dasDeviceInfo?.model?.brand &&
      dasDeviceInfo?.model?.version
    ) {
      dispatch(
        getDeviceByModel({
          brand: dasDeviceInfo.model.brand,
          model: dasDeviceInfo.model.model,
          version: dasDeviceInfo.model.version
        })
      );
    }
  }, [
    dasDeviceInfo?.model?.brand,
    dasDeviceInfo?.model?.model,
    dasDeviceInfo?.model?.version,
    dispatch
  ]);

  useEffect(() => {
    if (!balanceAndPin && serialNumber) {
      dispatch(
        getBalanceAndPin({ serial: serialNumber, query: "?provider=pannovate" })
      );
    }
  }, [balanceAndPin, serialNumber, dispatch]);

  const getDeviceImage = () => {
    if (
      discoveryDevice?.variants?.length > 0 &&
      dasDeviceInfo?.model?.variant?.color
    ) {
      const deviceVariant = discoveryDevice.variants.find(
        (variant) => variant.color === dasDeviceInfo.model.variant.color
      );

      if (deviceVariant?.image) {
        return deviceVariant.image;
      }
    } else if (discoveryDevice?.defaultImage) {
      return discoveryDevice.defaultImage;
    }

    return null;
  };

  if (isLoading || gettingDasDeviceInfo || isLoadingDevices) return <Spinner />;

  return (
    <>
      <MainContainer>
        <NavBar>
          <img src={rrMastercardLogo} alt="roland garos logo" />

          <div>
            Balance:{" "}
            {balanceAndPin?.balanceDetails?.availableBalance
              ? formattedBalance(
                  balanceAndPin?.balanceDetails?.availableBalance,
                  balanceAndPin?.balanceDetails?.currency,
                  true
                )
              : "N/A"}
          </div>
        </NavBar>
        <MastercardIntro>
          <h2>Mastercard Welcomes You to Roland Garros!</h2>
          <p>
            Scroll down to see the different functionality of your payment
            device.
          </p>
        </MastercardIntro>
        <Wearable className="wearable">
          <img src={getDeviceImage() || wearableImg} alt="wearable" />
        </Wearable>
        <GreenManifesto>
          <h2>
            Made from Appleskin. Vegan, Durable, Waterproof and Eco-friendly.
          </h2>
          <p>{`Make a purchase at the dedicated cashless Mastercard Food and Beverage kiosks near the Suzanne Lenglen court or at other participating points of sale within Roland-Garros and try your luck to access our exclusive Mastercard lounge at Jardin des Mousquetaires.`}</p>
        </GreenManifesto>
        <ExpandingContainer title="Event information">
          <EventInformation>
            <p>Get all the information you need on the Roland Garros app</p>
            <div className="stores">
              <img
                src={appStoreLogo}
                alt="app store logo"
                onClick={() =>
                  window.open(
                    "https://apps.apple.com/us/app/roland-garros-official/id1371584793",
                    "_blank"
                  )
                }
              />
              <img
                src={gplayLogo}
                alt="google play logo"
                onClick={() =>
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.netcosports.androlandgarros&hl=en&gl=US",
                    "_blank"
                  )
                }
              />
            </div>
          </EventInformation>
        </ExpandingContainer>
        <ExpandingContainer title="Payment Account">
          <AfterEvent>
            {balanceAndPin?.activationCode && (
              <>
                <img className="bb-logo" src={bbLogo} alt="bourgeois boheme" />
                <p>
                  Want to continue enjoying unrivaled payment experience?
                  Upgrade to Bourgeois Bohème wallet!
                </p>
                <p>Your wearable activation code</p>
                <div className="activation-code">
                  <h2>{balanceAndPin?.activationCode}</h2>
                  <img
                    src={copyIcon}
                    alt="copy icon"
                    onClick={() =>
                      navigator.clipboard.writeText(
                        balanceAndPin.activationCode
                      )
                    }
                  />
                </div>

                <div
                  className="button"
                  onClick={() =>
                    window.open(
                      `https://events.bobofin.com/?token=${balanceAndPin?.activationCode}`,
                      "_blank"
                    )
                  }
                >
                  {"Join Bourgeois Bohème"}{" "}
                  <img src={chevronRight} alt="chevron right" />
                </div>
                <div className="stores">
                  <img
                    src={appStoreLogo}
                    alt="app store logo"
                    onClick={() =>
                      window.open(
                        "https://apps.apple.com/us/app/bourgeois-boheme/id1574430730",
                        "_blank"
                      )
                    }
                  />
                  <img
                    src={gplayLogo}
                    alt="google play logo"
                    onClick={() =>
                      window.open(
                        "https://play.google.com/store/apps/details?id=com.paymeswiss.bobofin",
                        "_blank"
                      )
                    }
                  />
                </div>
              </>
            )}

            <>
              {balanceAndPin?.activationCode && (
                <div className="or-separator">
                  <span className="line"></span>
                  <p>or</p>
                  <span className="line"></span>
                </div>
              )}
              <img src={mmLogo} alt="manage-mii" />
              <p>Download the Manage-Mii app to connect your payment card.</p>
              <div className="stores">
                <img
                  src={appStoreLogo}
                  alt="app store logo"
                  onClick={() =>
                    window.open(
                      "https://apps.apple.com/gb/app/manage-mii/id6446908503",
                      "_blank"
                    )
                  }
                />
                <img
                  src={gplayLogo}
                  alt="google play logo"
                  onClick={() =>
                    window.open(
                      "https://play.google.com/store/apps/details?id=com.digiseq.managemii",
                      "_blank"
                    )
                  }
                />
              </div>
            </>
          </AfterEvent>
        </ExpandingContainer>
        <Footer>
          <p>Powered by</p>
          <img
            src={digiseqLogo}
            alt="digiseq-logo"
            onClick={() => window.open("https://www.digiseq.co.uk", "_blank")}
          />
          <p>Payment services provided by</p>
          <img
            src={paymeSwissLogo}
            alt="payme-swiss-logo"
            onClick={() => window.open("https://paymeswiss.com/", "_blank")}
          />
        </Footer>
      </MainContainer>
    </>
  );
};

export default RolandGarros24;
