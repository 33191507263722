import { Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

import {
  Divider,
  ListContainer,
  ListItem,
  MainContainer,
  Navbar
} from "./styles";
import chevronLeftIcon from "../../assets/faqs-icons/chevron-left.svg";

const FaqQuestion = () => {
  const { topic, subTopic, index } = useParams();
  const { t } = useTranslation("faqs");
  const navigate = useNavigate();

  const privacyPolicyUrl =
    "https://s3.eu-west-2.amazonaws.com/www.digiseq.co.uk/privacy-manage-mii.pdf";

  const termsAndConditionsUrl =
    "https://s3.eu-west-2.amazonaws.com/www.digiseq.co.uk/ManageMiiTerms.html";

  const question =
    topic === "mostAsked"
      ? t(`faqs:mostAsked.${subTopic}.question`)
      : t(`faqs:${topic}:${subTopic}.${index}.question`);
  const answer =
    topic === "mostAsked"
      ? t(`faqs:mostAsked.${subTopic}.answer`, {
          returnObjects: true,
          privacyPolicyUrl,
          termsAndConditionsUrl
        })
      : t(`faqs:${topic}:${subTopic}.${index}.answer`, {
          returnObjects: true,
          privacyPolicyUrl,
          termsAndConditionsUrl
        });

  return (
    <>
      <Navbar>
        <div className="back-button" onClick={() => navigate(-1)}>
          <img src={chevronLeftIcon} alt="chevron left" />
          <p>{t("back")}</p>
        </div>
      </Navbar>
      <MainContainer>
        <ListContainer>
          <ListItem direction={i18n.dir(i18n.language || "en")}>
            <div className="item-content">
              <p className="b1">{question}</p>
            </div>
          </ListItem>
          <Divider marginHorizontal={16} />
          <ListItem direction={i18n.dir(i18n.language || "en")}>
            <div className="item-content">
              {answer.split("//n").map((text, index, array) => {
                const urlMatch = text.match(/(https?:\/\/[^\s]+)/g);
                if (urlMatch) {
                  const parts = text.split(urlMatch[0]);
                  return (
                    <Fragment key={index}>
                      {parts[0]}
                      <a
                        href={urlMatch[0]}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        {t("faqs:link")}
                      </a>
                      {parts[1]}
                      {index !== array.length - 1 && <br />}
                    </Fragment>
                  );
                }
                return index === array.length - 1 ? (
                  text
                ) : (
                  <Fragment key={index}>
                    {text}
                    <br />
                  </Fragment>
                );
              })}
            </div>
          </ListItem>
        </ListContainer>
      </MainContainer>
    </>
  );
};

export default FaqQuestion;
