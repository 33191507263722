import {
  MainContainer,
  Header,
  BalanceContainer,
  Button,
  ErrorMessage
} from "./styles";
import logo from "./img/ldbx-logo.svg";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getBalanceAndPin } from "../../features/balance-and-pin/balanceAndPinSlice";
import { formattedBalance } from "../../features/balance-and-pin/utils";
import Spinner from "../../components/Spinner";

const Ldbx = () => {
  const [searchParams] = useSearchParams();
  const serialNumber = searchParams.get("serialNumber");
  const dispatch = useDispatch();

  const { balanceAndPin, isLoading, isError } = useSelector(
    (state) => state.balanceAndPin
  );

  useEffect(() => {
    dispatch(
      getBalanceAndPin({
        serial: serialNumber,
        query: "?provider=much_better"
      })
    );
  }, [dispatch, serialNumber]);

  if (isLoading) return <Spinner />;

  return (
    <MainContainer>
      <Header>
        <img src={logo} alt="LDBX" />
      </Header>
      <BalanceContainer>
        <h2>Your Balance is:</h2>
        <h2>{`${formattedBalance(
          balanceAndPin?.balanceDetails?.availableBalance,
          balanceAndPin?.balanceDetails?.currency
        )}`}</h2>
      </BalanceContainer>
      <Button onClick={() => window.open("https://ldbx.tech/", "_blank")}>
        Visit Our Website
      </Button>

      {isError && (
        <ErrorMessage>
          Something went wrong, please try later or contact support
        </ErrorMessage>
      )}
    </MainContainer>
  );
};

export default Ldbx;
