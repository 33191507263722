import styled, { css } from "styled-components";

const colors = {
  black: "#303033",
  white: "#ffffff",
  orange: "#FE7E45",
  background: "#F7F8FA",
  secondaryText: "#818699",
  searchBar: "#E6E8ED",
  dividers: "#AEB1B8"
};

export const Arimo = css`
  font-family: "Arimo";
  font-style: normal;
`;

export const Title = styled.h1`
  ${Arimo}
  font-weight: 600;
  font-size:  16px;
  line-height: 40px;
  color: ${colors.black};
  text-align: ${({ direction }) => (direction === "rtl" ? "right" : "left")};
`;

export const bodyText = css`
  ${Arimo}
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.25;
`;

export const Body2 = css`
  ${bodyText}
  color: ${colors.black};
  font-size: 14px;

`;

export const Subtitle = styled.h2`
  ${bodyText}
  color: ${colors.secondaryText};
  text-align: ${({ direction }) => (direction === "rtl" ? "right" : "left")};
  font-size: 18px;

`;

export const Body1 = css`
  ${bodyText}
  color: ${colors.secondaryText};
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  max-width: 480px;
  margin: auto;
  min-height: 100vh;
  padding: 16px 16px 64px;
  background-color: ${colors.background};
`;

export const Navbar = styled.div`
  max-width: 480px;
  margin: auto;
  width: 100%;
  display: flex;
  flex-direction: ${({ direction }) =>
    direction === "rtl" ? "row-reverse" : "row"};
  align-items: center;
  justify-content: space-between;
  padding: 16px 8px;
  background-color: ${colors.white};
  border-bottom: 1px solid ${colors.dividers};

  .back-button {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
      width: 32px;
      height: 32px;
    }
    p {
      ${Body2}
    }
  }
`;

export const SearchBar = styled.div`
  background-color: ${colors.searchBar};
  border-radius: 10px;
  padding: 12px 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
`;

export const Divider = styled.div`
  background-color: ${colors.dividers};
  height: 1px;
  margin: 0 ${({ marginHorizontal }) => marginHorizontal}px;
`;

export const SearchInput = styled.input`
  border: none;
  background: none;
  outline: none;
  width: 100%;
  ${Body1}
`;

export const SearchIcon = styled.img`
  width: 16px;
  height: 16px;
  position: absolute;
  left: 16px;
`;

export const ListContainer = styled.div`
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  border-radius: 10px;
`;

export const ListItem = styled.div`
  cursor: pointer;
  padding: 12px 16px;
  display: flex;
  flex-direction: ${({ direction }) =>
    direction === "rtl" ? "row-reverse" : "row"};
  text-align: ${({ direction }) => (direction === "rtl" ? "right" : "left")};
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  position: relative;
  min-height: ${({ minHeight }) => minHeight}px;
  p {
    ${Body2}
  }
  .item-content {
    display: flex;
    flex-direction: ${({ direction }) =>
      direction === "rtl" ? "row-reverse" : "row"};
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: relative;
    ${Divider} {
      position: absolute;
      right: -16px;
      left: 0;
      bottom: -16px;
    }
    .b1 {
      ${Body1}
    }
    a {
      ${Body1}
      color: ${colors.orange};
    }
  }
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.orange};
  border-radius: 50%;
  padding: 6px;

  img {
    width: 20px;
    height: 20px;
  }
`;

export const ChevronRightIcon = styled.img`
  width: 24px;
  height: 24px;
`;
