import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";

import {
  getBalanceAndPin,
  hasPersonalDetailsAction,
  savePersonalDetailsAction
} from "../../features/balance-and-pin/balanceAndPinSlice";
import {
  AfterEvent,
  Capabilities,
  Capability,
  EventInformation,
  Footer,
  GreenManifesto,
  InfoAlert,
  MainContainer,
  SectionContainer,
  SerialNumber,
  ViewBalance,
  Wearable
} from "./styles/Uefa";

import usePrevious from "../../hooks/usePrevious";

import Spinner from "../../components/Spinner";
import { validateName } from "../../utils/utils";
import { MasterCardDemoFonts } from "../mastercard-demo/styles/MasterCardDemoStyles";

import uefaMastercardLogo from "./img/uefa-mastercard-logo.svg";
import wearableImg from "./img/wearable.png";
import chevronRight from "./img/chevron-right.svg";
import bbLogo from "./img/bb.svg";
import appStoreLogo from "./img/app-store.svg";
import gplayLogo from "./img/play-store.svg";
import mmLogo from "./img/mm-logo.svg";
import digiseqLogo from "./img/digiseq-logo.png";
import paymeSwissLogo from "./img/payme-swiss-logo.png";
import personIcon from "./img/person.svg";
import phoneIcon from "./img/phone.svg";
import errorIcon from "./img/errorIcon.svg";
import checkTermsIcon from "./img/check-terms.svg";
import checkedTermsIcon from "./img/checked-terms.svg";
import infoCloseIcon from "./img/info-close-icon.svg";
import copyIcon from "./img/copy-icon.svg";

const UefaPromoReady = () => {
  const [searchParams] = useSearchParams();
  const serialNumber = searchParams.get("serialNumber");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [info, setInfo] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [nameError, setNameError] = useState("");
  const [phoneNotValid, setPhoneNotValid] = useState("");
  const [showUserRegistration, setShowUserRegistration] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [termsError, setTermsError] = useState(false);
  const [showPin, setShowPin] = useState(false);

  const upgradable = searchParams.get("upgradable") === "true";

  const infoDetails = {
    shopping: {
      icon: require("./img/shopping.png"),
      title: "Shopping",
      subtitle:
        "We have pre-loaded your key fob with €20, so you can shop easily at our kiosk and also tap for your free drink and snack during half-time at our dedicated concession stands."
    },
    donations: {
      icon: require("./img/donations.png"),
      title: "Pitches of Hope",
      subtitle:
        "By supporting the ‘Pitches of Hope’ initiative, you help and the UEFA Foundation™ build newMastercard football pitches in the regions of Turkey affected by the recent earthquake.",
      howToDonate: {
        title: "You can donate via: ",
        options: [
          { icon: require("./img/kiosk.png"), text: "Digital Kiosk" },
          {
            icon: require("./img/terminal.png"),
            text: "Tap to donate terminals"
          },
          {
            icon: require("./img/game-experience.png"),
            text: "Football game experience"
          }
        ]
      }
    },

    identification: {
      icon: require("./img/identification.png"),
      title: "Identification",
      subtitle:
        "Not only can you use it to safely store your belongings in the cloakroom, it will also help you access and keep score on the AI-powered football game experience.​"
    },
    sustainability: {
      icon: require("./img/sustainability.png"),
      title: "Sustainability",
      subtitle:
        "Mastercard aims to prioritise sustainability and innovation in all that we do. This key fob is no exception. Made out of apple skin, sourced from Italian South Tyrol, every kilogram of apple waste used to make it substitutes oil derivatives, saving 5.28Kg of CO2 (as compared with PU).​"
    }
  };

  const eventInformation = [
    { time: "18:00", details: "Hospitality Area Opening" },
    { time: "19:30", details: "Official Welcoming" },
    { time: "19:45", details: "Ambassadors Q&As" },
    { time: "21:00", details: "Last Order Before the Match" },
    { time: "21:20", details: "Leave for Stadium" },
    { time: "22:00", details: "Match Kick-off" },
    { time: "00:00", details: "Match Ends (estimated)" },
    { time: "01:00", details: "Transfers to Hotel(s)" },
    {
      time: "02:00",
      details: "Hospitality Area Closes (or 2 hours after the final whistle)"
    }
  ];

  const {
    balanceAndPin,
    isLoading,
    hasPersonalDetails,
    gettingPersonalDetails,
    errorGettingPersonalDetails,
    savingPersonalDetails,
    errorSavingPersonalDetails
  } = useSelector((state) => state.balanceAndPin);

  const prevSavingPersonalDetails = usePrevious(savingPersonalDetails);

  useEffect(() => {
    dispatch(hasPersonalDetailsAction(serialNumber));
  }, [dispatch, serialNumber]);

  useEffect(() => {
    if (
      prevSavingPersonalDetails &&
      !errorSavingPersonalDetails &&
      serialNumber
    ) {
      dispatch(hasPersonalDetailsAction(serialNumber));
    }
  }, [
    dispatch,
    errorSavingPersonalDetails,
    prevSavingPersonalDetails,
    serialNumber
  ]);

  useEffect(() => {
    if (!balanceAndPin && serialNumber && hasPersonalDetails) {
      dispatch(
        getBalanceAndPin({
          serial: serialNumber,
          query: "?provider=pannovate"
        })
      );
    }
  }, [balanceAndPin, serialNumber, dispatch, hasPersonalDetails]);

  const handleUserRegistration = () => {
    if (!name || !validateName(name)) {
      setNameError(true);
    } else if (!isValidPhoneNumber(phone)) {
      setPhoneNotValid(true);
    } else {
      setShowUserRegistration(false);
      dispatch(
        savePersonalDetailsAction({
          serial: serialNumber,
          details: { fullName: name, phoneNumber: phone }
        })
      );
    }
  };

  const formattedBalance = (balanceDetails) => {
    const { availableBalance, currency } = balanceDetails;
    let iconPrefixed = true;
    let icon = "";

    switch (currency) {
      case "GBP":
        icon = "£";
        break;
      case "EUR":
        icon = "€";
        break;
      case "USD":
        icon = "$";
        break;
      default:
        icon = "";
    }

    if (iconPrefixed) {
      return icon + availableBalance;
    } else {
      return availableBalance + icon;
    }
  };

  const ViewBalanceLabel = () =>
    upgradable ? (
      <span>{`View ${
        searchParams.get("includePin") === "true" ? " Pin and " : ""
      } Balance`}</span>
    ) : (
      <span>Verificar saldo</span>
    );

  if (isLoading || gettingPersonalDetails || savingPersonalDetails)
    return <Spinner />;

  return (
    <>
      <MasterCardDemoFonts />
      <MainContainer>
        <SerialNumber>
          <div />
          <p>{serialNumber}</p>
          <img
            src={copyIcon}
            alt="copy icon"
            onClick={() => navigator.clipboard.writeText(serialNumber)}
          />
        </SerialNumber>
        <GreenManifesto>
          <img src={uefaMastercardLogo} alt="uefa logo" />
          {upgradable && (
            <>
              <h2>Sharing the love of football: priceless</h2>
              <p>{`There is something incredible about football.`}</p>
              <p>{` It’s not just the goals, the teams or the players.`}</p>
              <p>{` It’s that feeling that we can’t help but share our love for it.`}</p>
              <p>{` So let our passion for football inspire life-long relationships.`}</p>
              <p>{` Because when it does, it’s truly amazing.`}</p>
            </>
          )}
        </GreenManifesto>
        <ViewBalance
          isButton={!hasPersonalDetails}
          onClick={() => !hasPersonalDetails && setShowUserRegistration(true)}
        >
          {!hasPersonalDetails ? (
            <ViewBalanceLabel />
          ) : (
            <div className="balance-container">
              <p>My Balance</p>
              <div className="balance">
                {balanceAndPin?.balanceDetails?.availableBalance
                  ? formattedBalance(balanceAndPin?.balanceDetails)
                  : "N/A"}
              </div>
              {searchParams.get("includePin") === "true" &&
                balanceAndPin?.pinDetails?.pin && (
                  <div
                    onClick={() => (!showPin ? setShowPin(true) : null)}
                    className="view-pin-button"
                  >
                    View PIN
                  </div>
                )}
            </div>
          )}
          {showPin && (
            <InfoAlert>
              <div className="inner-container">
                <img
                  className="close-button"
                  src={infoCloseIcon}
                  alt="info-close-icom"
                  onClick={() => setShowPin(false)}
                />
                <h2>PIN</h2>
                <h1>{balanceAndPin?.pinDetails?.pin}</h1>
                <p>Your PIN may be required to complete a transaction.</p>
              </div>
            </InfoAlert>
          )}
        </ViewBalance>
        <Wearable className="wearable">
          <img src={wearableImg} alt="wearable" />
        </Wearable>
        {upgradable && (
          <SectionContainer>
            <h2>{"Key fob Features"}</h2>
            <Capabilities>
              <div className="capabilities-container">
                <Capability onClick={() => setInfo("shopping")}>
                  <img src={require("./img/shopping.png")} alt="shopping" />
                  <h3>Shopping</h3>
                </Capability>
                <Capability onClick={() => navigate("/uefa-pitches-of-hope/")}>
                  <img src={require("./img/donations.png")} alt="donations" />
                  <h3>Pitches of Hope</h3>
                </Capability>
                <Capability onClick={() => setInfo("identification")}>
                  <img
                    src={require("./img/identification.png")}
                    alt="identification"
                  />
                  <h3>Additional Functionality</h3>
                </Capability>
                <Capability onClick={() => setInfo("sustainability")}>
                  <img
                    src={require("./img/sustainability.png")}
                    alt="sustainability"
                  />
                  <h3>Sustainability</h3>
                </Capability>
              </div>
            </Capabilities>
          </SectionContainer>
        )}
        {upgradable && (
          <SectionContainer>
            <h2>{"Today's Schedule"}</h2>
            <EventInformation>
              {eventInformation.map((evt) => (
                <p>
                  <span>{evt.time}</span>
                  {` - ${evt.details}`}
                </p>
              ))}
            </EventInformation>
          </SectionContainer>
        )}
        <SectionContainer>
          <h2>{"Payment Account"}</h2>
          <AfterEvent>
            <img className="bb-logo" src={bbLogo} alt="bourgeois boheme" />
            <p>
              Want to continue enjoying unrivaled payment experience? Upgrade to
              Bourgeois Bohème wallet!
            </p>
            <div
              className="button"
              onClick={() => window.open(`https://uefa.bobofin.com/`, "_blank")}
            >
              {"Join Bourgeois Bohème"}{" "}
              <img src={chevronRight} alt="chevron right" />
            </div>
            <p className="note">
              Onboarding is currently available only to EEA, UK and Swiss
              residents.
            </p>

            <div className="stores">
              <img
                src={appStoreLogo}
                alt="app store logo"
                onClick={() =>
                  window.open(
                    "https://apps.apple.com/us/app/bourgeois-boheme/id1574430730",
                    "_blank"
                  )
                }
              />
              <img
                src={gplayLogo}
                alt="google play logo"
                onClick={() =>
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.paymeswiss.bobofin",
                    "_blank"
                  )
                }
              />
            </div>
            {upgradable && (
              <>
                <div className="or-separator">
                  <span className="line"></span>
                  <p>or</p>
                  <span className="line"></span>
                </div>

                <img src={mmLogo} alt="manage-mii" />
                <p>Download the Manage-Mii app to connect your payment card.</p>
                <div className="stores">
                  <img
                    src={appStoreLogo}
                    alt="app store logo"
                    onClick={() =>
                      window.open(
                        "https://apps.apple.com/gb/app/manage-mii/id6446908503",
                        "_blank"
                      )
                    }
                  />
                  <img
                    src={gplayLogo}
                    alt="google play logo"
                    onClick={() =>
                      window.open(
                        "https://play.google.com/store/apps/details?id=com.digiseq.managemii",
                        "_blank"
                      )
                    }
                  />
                </div>
              </>
            )}
          </AfterEvent>
        </SectionContainer>
        <Footer>
          <p>Powered by</p>
          <img
            src={digiseqLogo}
            alt="digiseq-logo"
            onClick={() => window.open("https://www.digiseq.co.uk", "_blank")}
          />
          <p>Payment services provided by</p>
          <img
            src={paymeSwissLogo}
            alt="payme-swiss-logo"
            onClick={() => window.open("https://paymeswiss.com/", "_blank")}
          />
        </Footer>
        {info && (
          <InfoAlert>
            <div className="inner-container">
              <img
                className="close-button"
                src={infoCloseIcon}
                alt="info-close-icom"
                onClick={() => setInfo("")}
              />
              <img
                className="top-icon"
                src={infoDetails[info].icon}
                alt="icon"
              />
              <h2>{infoDetails[info].title}</h2>
              <p>{infoDetails[info].subtitle}</p>
              {infoDetails[info].howToDonate?.title && (
                <div className="how-to-donate">
                  <p>{infoDetails[info].howToDonate?.title}</p>
                  <div className="donate-options">
                    {infoDetails[info].howToDonate?.options.map((option) => (
                      <div className="donate-option" key={option.text}>
                        <img src={option.icon} alt="donate-option-icon" />
                        <p>{option.text}</p>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </InfoAlert>
        )}

        {showUserRegistration && (
          <InfoAlert>
            <div className="inner-container">
              <h2>View Balance</h2>
              <p>
                Please provide your details. It will be requested only once.
              </p>

              <div className={`input-container ${nameError && "input-error"}`}>
                <img src={personIcon} alt="person icon" />
                <input
                  required
                  placeholder="Full name"
                  value={name}
                  onChange={(ev) => {
                    setNameError("");
                    setName(ev.target.value);
                  }}
                />
              </div>
              <div
                className={`input-container ${phoneNotValid && "input-error"}`}
              >
                <img src={phoneIcon} alt="phone icon" />

                <PhoneInput
                  required
                  maxLength="20"
                  defaultCountry="GB"
                  placeholder="Mobile Number"
                  value={phone}
                  onChange={(value) => {
                    setPhoneNotValid(false);
                    setPhone(value);
                  }}
                />
                {phoneNotValid && <img src={errorIcon} alt="error icon" />}
              </div>
              {(nameError || phoneNotValid) && (
                <div className="error-msg">Please input valid information</div>
              )}
              <div className="terms">
                <img
                  onClick={() => {
                    setTermsError(false);
                    setTermsAccepted(!termsAccepted);
                  }}
                  src={termsAccepted ? checkedTermsIcon : checkTermsIcon}
                  alt="accept-terms-icon"
                />
                <p>
                  I accept the{" "}
                  <a
                    href="https://s3.eu-west-2.amazonaws.com/www.digiseq.co.uk/UEFA_BoBo_TnC.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms and Conditions.
                  </a>
                </p>
              </div>
              {termsError && (
                <div className="error-msg">
                  Please accept terms and conditions.
                </div>
              )}
              <div
                className="button"
                onClick={() => {
                  if (!termsAccepted) {
                    setTermsError(true);
                  } else {
                    handleUserRegistration();
                  }
                }}
              >
                View Balance
              </div>
              <div
                onClick={() => setShowUserRegistration(false)}
                className="button button-secondary"
              >
                Cancel
              </div>
            </div>
          </InfoAlert>
        )}
      </MainContainer>
    </>
  );
};

export default UefaPromoReady;
