import axios from "axios";
import { ENV } from "../../env";

export const WEARABLES_API_URL = `https://www.${
  ENV !== "production" ? (ENV === "development" ? "dev" : ENV) + "-" : ""
}manage-mii.uk/api/wearables`;

export const DEVICE_API_URL = `https://www.${
  ENV !== "production" ? (ENV === "development" ? "dev" : ENV) + "-" : ""
}manage-mii.uk/api/das/device`;

export const RECIPE_HARVEST_API_URL = `https://www.${
  ENV !== "production" ? (ENV === "development" ? "dev" : ENV) + "-" : ""
}manage-mii.uk/api/das/recipe-harvest`;

const getBalanceAndPin = async (serial, query) => {
  if (query === "?provider=recipe-harvest") {
    const response = await axios.get(
      RECIPE_HARVEST_API_URL + `/${serial}/details`
    );

    return response.data;
  }

  const response = await axios.get(
    WEARABLES_API_URL + `/${serial}/prepaid/anonymous/${query ? query : ""}`
  );

  return response.data;
};

const hasPersonalDetails = async (serial) => {
  const response = await axios.get(DEVICE_API_URL + `/${serial}/user-details`);

  return response.data;
};

const savePersonalDetails = async ({ serial, details }) => {
  const response = await axios.put(
    DEVICE_API_URL + `/${serial}/user-details`,
    details
  );

  return response.data;
};

const balanceAndPinService = {
  getBalanceAndPin,
  hasPersonalDetails,
  savePersonalDetails
};

export default balanceAndPinService;
