import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MainContainer, Navbar, Form, Input, Button, Footer } from "./styles";
import logo from "./img/logo.svg";
import logoFooter from "./img/logo-alternative.svg";
import Spinner from "../../components/Spinner";
import { savePersonalDetailsAction } from "../../features/balance-and-pin/balanceAndPinSlice";

import packageJson from "../../../package.json";
import usePrevious from "../../hooks/usePrevious";

const RecipeHarvest = () => {
  const [searchParams] = useSearchParams();
  const serialNumber = searchParams.get("serialNumber");
  const tFlag = searchParams.get("t");

  const [formData, setFormData] = useState({
    lastName: "",
    email: "",
    phone: ""
  });

  const [errors, setErrors] = useState({
    fullName: "",
    email: "",
    phone: ""
  });

  const [errorMessage, setErrorMessage] = useState("");
  const [copied, setCopied] = useState(false);

  const dispatch = useDispatch();
  const { isLoading, savingPersonalDetails, errorSavingPersonalDetails } =
    useSelector((state) => state.balanceAndPin);

  const prevSavingPersonalDetails = usePrevious(savingPersonalDetails);

  useEffect(() => {
    if (prevSavingPersonalDetails && !errorSavingPersonalDetails) {
      window.location.href = `https://www.recipe-harvest.com/?serialNumber=${serialNumber}${
        tFlag ? "&t=1" : ""
      }`;
    }
  }, [
    errorSavingPersonalDetails,
    prevSavingPersonalDetails,
    serialNumber,
    tFlag
  ]);

  useEffect(() => {
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }, [copied]);

  const formErrorMessages = {
    fullName: "Full name is required",
    email: "Email is required",
    phone: "Phone is required",
    submit: "Please fill in all fields"
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.fullName) {
      setErrors({ ...errors, fullName: formErrorMessages.fullName });
      return;
    }
    if (!formData.email) {
      setErrors({ ...errors, email: formErrorMessages.email });
      return;
    }
    if (!formData.phone) {
      setErrors({ ...errors, phone: formErrorMessages.phone });
      return;
    }
    dispatch(
      savePersonalDetailsAction({
        serial: serialNumber,
        details: {
          fullName: formData.fullName,
          email: formData.email,
          phoneNumber: formData.phone
        }
      })
    );
  };

  const handleChange = (e) => {
    setErrors({ ...errors, [e.target.name]: "" });
    setErrorMessage("");
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  if (isLoading) return <Spinner />;

  return (
    <MainContainer>
      <Navbar>
        <img src={logo} alt="Recipe Harvest" />
      </Navbar>
      <Form onSubmit={handleSubmit}>
        <h2>Register</h2>
        <Input
          type="text"
          placeholder="Full name"
          name="fullName"
          value={formData.fullName}
          onChange={handleChange}
          error={errors.fullName}
        />
        {errors.fullName && <p className="error-message">{errors.fullName}</p>}
        <Input
          type="email"
          placeholder="Email"
          name="email"
          onChange={handleChange}
          value={formData.email}
          error={errors.email}
        />
        {errors.email && <p className="error-message">{errors.email}</p>}
        <Input
          type="tel"
          placeholder="Phone Number"
          name="phone"
          onChange={handleChange}
          value={formData.phone}
          error={errors.phone}
        />
        {errors.phone && <p className="error-message">{errors.phone}</p>}
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        <Button type="submit">Submit</Button>
      </Form>
      <Footer>
        <div className="logo">
          <img src={logoFooter} alt="Recipe Harvest" />
        </div>
        <p>© 2023 Recipe Harvest </p>
        <div
          className="copyToClipboard"
          onClick={() => {
            navigator.clipboard.writeText(serialNumber);
            setCopied(true);
          }}
        >
          <p>ID: {serialNumber}</p>
        </div>
        {copied && <p>ID copied to clipboard</p>}
        <div className="version">
          <p>v.{packageJson.version}</p>
        </div>
      </Footer>
    </MainContainer>
  );
};

export default RecipeHarvest;
