import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import translationEN from "./en/translation.json";
import translationDE from "./de/translation.json";
import translationFR from "./fr/translation.json";
import faqsEN from "./en/faqs.json";
import faqsDE from "./de/faqs.json";
import faqsFR from "./fr/faqs.json";
import faqsCS from "./cs/faqs.json";
import faqsAR from "./ar/faqs.json";
import faqsNL from "./nl/faqs.json";
import faqsHE from "./he/faqs.json";
import faqsIT from "./it/faqs.json";
import faqsKK from "./kk/faqs.json";
import faqsRU from "./ru/faqs.json";
import faqsSK from "./sk/faqs.json";
import faqsES from "./es/faqs.json";
import faqsPL from "./pl/faqs.json";
import faqsZHHant from "./zh-Hant/faqs.json";

export const defaultNS = "main";

export const resources = {
  en: {
    main: translationEN,
    faqs: faqsEN
  },
  de: {
    main: translationDE,
    faqs: faqsDE
  },
  fr: {
    main: translationFR,
    faqs: faqsFR
  },
  cs: {
    faqs: faqsCS
  },
  ar: {
    faqs: faqsAR
  },
  nl: {
    faqs: faqsNL
  },
  he: {
    faqs: faqsHE
  },
  it: {
    faqs: faqsIT
  },
  kk: {
    faqs: faqsKK
  },
  ru: {
    faqs: faqsRU
  },
  sk: {
    faqs: faqsSK
  },
  es: {
    faqs: faqsES
  },
  pl: {
    faqs: faqsPL
  },
  "zh-Hant": {
    faqs: faqsZHHant
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    compatibilityJSON: "v3",
    ns: ["main", "common"],
    fallbackLng: "en",
    resources,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
