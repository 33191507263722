import React, { useEffect } from "react";
import {
  Footer,
  Hero,
  MainContainer,
  Separator,
  StepContainer,
  StepIcon
} from "./styles/Tap2Promo";

import logo from "./img/logo.png";
import registerIcon from "./img/register-icon.svg";
import AppStoreLinks from "./AppStoreLinks";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { getDeviceByModel } from "../../features/devices/deviceSlice";
import { getDasDeviceInfo } from "../../features/das-device-manager/dasDeviceManagerSlice";
import Spinner from "../../components/Spinner";

const Tap2Promo = () => {
  const [searchParams] = useSearchParams();
  const serialNumber = searchParams.get("serialNumber");
  const dispatch = useDispatch();

  const { device: discoveryDevice, isLoading: isLoadingDevices } = useSelector(
    (state) => state.devices
  );

  const { dasDeviceInfo, gettingDasDeviceInfo } = useSelector(
    (state) => state.dasDevices
  );

  console.log({ discoveryDevice });

  useEffect(() => {
    if (!dasDeviceInfo) {
      dispatch(getDasDeviceInfo(serialNumber));
    }
  }, [dasDeviceInfo, dispatch, serialNumber]);

  useEffect(() => {
    if (
      dasDeviceInfo?.model?.model &&
      dasDeviceInfo?.model?.brand &&
      dasDeviceInfo?.model?.version
    ) {
      dispatch(
        getDeviceByModel({
          brand: dasDeviceInfo.model.brand,
          model: dasDeviceInfo.model.model,
          version: dasDeviceInfo.model.version
        })
      );
    }
  }, [
    dasDeviceInfo?.model?.brand,
    dasDeviceInfo?.model?.model,
    dasDeviceInfo?.model?.version,
    dispatch
  ]);

  const getDeviceImage = () => {
    if (
      discoveryDevice?.variants?.length > 0 &&
      dasDeviceInfo?.model?.variant?.color
    ) {
      const deviceVariant = discoveryDevice.variants.find(
        (variant) => variant.color === dasDeviceInfo.model.variant.color
      );

      if (deviceVariant?.image) {
        return deviceVariant.image;
      }
    } else if (discoveryDevice?.defaultImage) {
      return discoveryDevice.defaultImage;
    }

    return null;
  };

  if (gettingDasDeviceInfo || isLoadingDevices) return <Spinner />;

  return (
    <MainContainer>
      <Hero>
        <img className="logo" src={logo} alt="tap2" />
        {getDeviceImage() ? (
          <div className="wearable-container">
            <img src={getDeviceImage()} alt="wearable" />
          </div>
        ) : null}
        <h1>{"Welcome to TAP2!"}</h1>
        <div className="welcome-badge">
          <img src={registerIcon} alt="register" />
          <p>
            {
              "You can now set your contactless wearable up in just 3 steps. Make sure to follow the steps below to set up contactless payment."
            }
          </p>
        </div>
      </Hero>
      <Separator />
      <StepContainer>
        <StepIcon>
          <p>1</p>
        </StepIcon>
        <h2>{"Get a Curve Account!"}</h2>
        <div className="video-container">
          <video
            src="https://s3.eu-west-2.amazonaws.com/www.digiseq.co.uk/tap2/tap2-2.webm"
            playsInline
            preload="auto"
            controls
          />
        </div>
        <p>
          {
            "Curve is your digital wallet that consolidates all your bank cards in one place, giving you the flexibility to choose which card connects to your wearable each day. To get started, download the Curve app using the link below."
          }
        </p>
        <p>{"If you're already using Curve, proceed to the next step."}</p>
        <AppStoreLinks
          appStoreUrl={
            "https://onelink.curve.com/1WAh?pid=business_development&c=bd_website_signup_tap2_mainlink_17022025&reward_amount=0&promo_code=0&display_name=TAP2&af_channel=Wearables&af_partner_name=TAP2&af_placement=Website"
          }
          gPlayUrl={
            "https://onelink.curve.com/1WAh?pid=business_development&c=bd_website_signup_tap2_mainlink_17022025&reward_amount=0&promo_code=0&display_name=TAP2&af_channel=Wearables&af_partner_name=TAP2&af_placement=Website"
          }
        />
      </StepContainer>
      <StepContainer>
        <StepIcon>
          <p>2</p>
        </StepIcon>
        <h2>{"Download Manage-Mii App"}</h2>
        <div className="video-container">
          <video
            src="https://s3.eu-west-2.amazonaws.com/www.digiseq.co.uk/tap2/tap2-3.webm"
            playsInline
            preload="auto"
            controls
          />
        </div>
        <p>
          {
            "The Manage-Mii app is the central hub for managing your wearable. From here, you can easily add your Curve card and control your device. If you ever lose your wearable, you can instantly deactivate it within seconds, ensuring your information remains safe and secure."
          }
        </p>
        <AppStoreLinks
          appStoreUrl={"https://apps.apple.com/app/manage-mii/id6446908503"}
          gPlayUrl={
            "https://play.google.com/store/apps/details?id=com.digiseq.managemii"
          }
        />
      </StepContainer>
      <StepContainer>
        <StepIcon>
          <p>3</p>
        </StepIcon>
        <h2>{"Complete the In-App Setup"}</h2>
        <div className="video-container">
          <video
            src="https://s3.eu-west-2.amazonaws.com/www.digiseq.co.uk/tap2/tap2-4.webm"
            playsInline
            preload="auto"
            controls
          />
        </div>
        <p>
          {
            "You're almost done! Just follow the remaining in-app instructions to complete the setup of your wearable and start making payments on the go. Once you've finished, there’s nothing more you need to do—your wearable is ready to use hassle-free."
          }
        </p>
      </StepContainer>
      <Footer>
        <a
          href="https://www.manage-mii.co.uk/"
          target="_blank"
          rel="noreferrer"
        >
          <p>{"Manage-Mii"}</p>
        </a>
        <a href="https://digiseq.co.uk/" target="_blank" rel="noreferrer">
          <p>{"DIGISEQ"}</p>
        </a>
        <a href="https://www.tap-2.com/" target="_blank" rel="noreferrer">
          <p>{"TAP-2"}</p>
        </a>
        <a
          href="https://tap-2.com/terms-and-conditions/"
          target="_blank"
          rel="noreferrer"
        >
          <p>{"Terms and conditions"}</p>
        </a>
      </Footer>
    </MainContainer>
  );
};

export default Tap2Promo;
