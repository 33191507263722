import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import {
  getBalanceAndPin,
  savePersonalDetailsAction
} from "../../features/balance-and-pin/balanceAndPinSlice";
import usePrevious from "../../hooks/usePrevious";
import Spinner from "../../components/Spinner";
import {
  Main,
  Container,
  Navbar,
  PrimaryButton,
  SecondaryButton,
  Alert,
  Content
} from "./styles";

import closeIcon from "./img/closeIcon.svg";
import bgImage from "./img/velo-com-min.png";
import { getDeviceInfo } from "../../features/hugo-boss/hugoBossSlice";
import { formattedBalance } from "../../features/balance-and-pin/utils";
import pjson from "../../../package.json";

const Velo = () => {
  const [searchParams] = useSearchParams();
  const serialNumber = searchParams.get("serialNumber");

  const [showForm, setShowForm] = useState(false);
  const [showBalance, setShowBalance] = useState(false);
  const [showPaymentDetails, setShowPaymentDetails] = useState(false);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});

  const dispatch = useDispatch();

  const {
    balanceAndPin,
    isLoading,
    savingPersonalDetails,
    errorSavingPersonalDetails
  } = useSelector((state) => state.balanceAndPin);

  const { device, gettingDeviceInfo, gettingDeviceInfoError } = useSelector(
    (state) => state.hugoBoss
  );

  const prevGettingDeviceInfo = usePrevious(gettingDeviceInfo);
  const prevSavingPersonalDetails = usePrevious(savingPersonalDetails);

  useEffect(() => {
    if (prevGettingDeviceInfo && !gettingDeviceInfoError) {
      if (device?.personalDetails?.fullName && device?.personalDetails?.email) {
        setFullName(device?.personalDetails?.fullName);
        setEmail(device?.personalDetails?.email);
        setShowForm(false);
        setShowBalance(true);
      } else {
        // setShowForm(true);
        setShowBalance(false);
      }
    }
  }, [
    device?.personalDetails?.fullName,
    device?.personalDetails?.email,
    gettingDeviceInfoError,
    prevGettingDeviceInfo
  ]);

  useEffect(() => {
    if (prevSavingPersonalDetails && !errorSavingPersonalDetails) {
      setShowForm(false);
      setShowBalance(true);
    }
  }, [errorSavingPersonalDetails, prevSavingPersonalDetails]);

  useEffect(() => {
    dispatch(getDeviceInfo(serialNumber));
  }, [dispatch, serialNumber]);

  useEffect(() => {
    if (!balanceAndPin && serialNumber) {
      dispatch(
        getBalanceAndPin({
          serial: serialNumber,
          query: "?provider=pannovate"
        })
      );
    }
  }, [balanceAndPin, serialNumber, dispatch]);

  const validateForm = () => {
    let formErrors = {};

    const namePattern = /^[A-Za-zÀ-ÖØ-öø-ÿ\s'-]+$/;
    if (!fullName.trim()) {
      formErrors.fullName = "Name is required";
    } else if (!namePattern.test(fullName)) {
      formErrors.fullName = "Name is invalid";
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email.trim()) {
      formErrors.email = "Email is required";
    } else if (!emailPattern.test(email)) {
      formErrors.email = "Email is invalid";
    }

    setErrors(formErrors);

    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      dispatch(
        savePersonalDetailsAction({
          serial: serialNumber,
          details: { fullName, email }
        })
      );
    }
  };

  if (isLoading || gettingDeviceInfo) return <Spinner />;

  return (
    <Main>
      <Container>
        <Navbar showBalance={showBalance}>
          {showBalance && (
            <PrimaryButton onClick={() => setShowPaymentDetails(true)}>
              Recharge
            </PrimaryButton>
          )}
          {showBalance ? (
            <p className="balance">
              Balance:{" "}
              {formattedBalance(
                balanceAndPin?.balanceDetails?.availableBalance,
                balanceAndPin?.balanceDetails?.currency
              )}
            </p>
          ) : (
            <SecondaryButton onClick={() => setShowForm(true)}>
              View Balance
            </SecondaryButton>
          )}
        </Navbar>
        <Content>
          <img className="background" src={bgImage} alt="Velo background" />
        </Content>
      </Container>
      {showPaymentDetails && (
        <Alert>
          <div className="inner-container">
            <img
              className="close-button"
              src={closeIcon}
              alt="close-icon"
              onClick={() => setShowPaymentDetails(false)}
            />
            <div className="payment-details">
              <h2 className="title">Payment Details</h2>
              <div className="iban">
                <p>IBAN</p>
                <p>GB1234567891234</p>
              </div>
              <div className="reference">
                <p>Reference</p>
                <p>{`${balanceAndPin?.activationCode || "1234567890"}`}</p>
              </div>
            </div>
          </div>
        </Alert>
      )}
      {showForm && (
        <Alert>
          <div className="inner-container">
            <img
              className="close-button"
              src={closeIcon}
              alt="close-icon"
              onClick={() => setShowForm(false)}
            />
            <div className="form">
              <div className="nav">
                <h2 className="title">Welcome</h2>
              </div>
              <p className="subtitle">
                Please register your details to proceed
              </p>
              <div className="inputs">
                <div className="form-group">
                  <input
                    value={fullName}
                    id="fullName"
                    type="text"
                    onChange={(ev) => setFullName(ev.target.value)}
                    placeholder="Full Name"
                  />
                  {errors.fullName && (
                    <p className="error">{errors.fullName}</p>
                  )}
                </div>
                <div className="form-group">
                  <input
                    value={email}
                    id="email"
                    type="email"
                    onChange={(ev) => setEmail(ev.target.value)}
                    placeholder="Email"
                  />
                  {errors.email && <p className="error">{errors.email}</p>}
                </div>
              </div>

              <PrimaryButton
                disabled={savingPersonalDetails}
                onClick={() => handleSubmit()}
              >
                Submit
              </PrimaryButton>
              <p className="subtitle">
                By submitting your details, you agree to the{" "}
                <a href="https://www.velo.com/ch/en/velo-thr">
                  Terms and Conditions
                </a>{" "}
                and declare that you are over 18 years old.
              </p>
              {errorSavingPersonalDetails && (
                <p className="error">
                  {
                    "We are sorry, an error occurred, please try again later or contact support"
                  }
                </p>
              )}
            </div>
          </div>
        </Alert>
      )}
      <p className="version">v{pjson.version}</p>
      <p className="serial-number">{serialNumber}</p>
    </Main>
  );
};

export default Velo;
