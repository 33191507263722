import httpClient from "../../axios";
import { ENV } from "../../env";

export const API_URL_DAS = `https://www.${
  ENV !== "production" ? (ENV === "development" ? "dev" : ENV) + "-" : ""
}manage-mii.uk/api/das/business-card`;

export const API_URL_WEARABLES = `https://www.${
  ENV !== "production" ? (ENV === "development" ? "dev" : ENV) + "-" : ""
}manage-mii.uk/api/wearables`;

const SOCIAL_LINKS = {
  facebook: "https://www.facebook.com/",
  x: "https://www.x.com/",
  linkedin: "https://www.linkedin.com/",
  instagram: "https://www.instagram.com/",
  whatsapp: "https://wa.me/"
};

const getBusinessCard = async (serial) => {
  try {
    const response = await httpClient.get(
      API_URL_WEARABLES + `/${serial}/business-card`
    );

    if (response.data.social) {
      response.data.social = Object.keys(response.data.social).map((key) => {
        return {
          name: key,
          link: response.data.social[key].includes("http")
            ? response.data.social[key]
            : SOCIAL_LINKS[key] + response.data.social[key]
        };
      });
    }

    return response.data;
  } catch (error) {
    const response = await httpClient.get(API_URL_DAS + `/${serial}`);

    if (response.data?.promoReadyData?.social) {
      response.data.promoReadyData.social = Object.keys(
        response.data.promoReadyData.social
      ).map((key) => {
        return {
          name: key,
          link: response.data.promoReadyData.social[key].includes("http")
            ? response.data.promoReadyData.social[key]
            : SOCIAL_LINKS[key] + response.data.promoReadyData.social[key]
        };
      });
    }

    return response.data?.promoReadyData;
  }
};

const businessCardService = {
  getBusinessCard
};

export default businessCardService;
